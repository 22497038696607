import request from '@/plugins/axios'


// 关注列表
export const apiCommunityFollowLists = (params: any) => request.get('/community.community_follow/lists', { params })

// 添加关注
export const apiCommunityFollowAdd = (params: any) => request.post('/community.community_follow/add', params)

// 编辑关注
export const apiCommunityFollowEdit = (params: any) => request.post('/community.community_follow/edit', params)

// 删除关注
export const apiCommunityFollowDelete = (params: any) => request.post('/community.community_follow/delete', params)

// 关注详情
export const apiCommunityFollowDetail = (params: any) => request.get('/community.community_follow/detail', { params })

export const apiCommunityFollowSwitchStatus = (params: any) => request.post('/community.community_follow/status', params)